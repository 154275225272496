/* General styling for the work section */
.work-section {
  text-align: center;
  padding: 20px;
  color: white;
  margin-top: 40px;
}
.work-section hr {
  width: 70%;
  margin: auto;
  margin-bottom: 40px;
}

/* Styling for the main heading */
.work-heading {
  font-size: 2.5rem;
  margin-bottom: 40px;
}

/* Flexbox setup for each work item */
.work-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  padding-bottom: 30px;
  padding-top: 20px;
  width: 940px;
  margin: auto;
  cursor: pointer;
}

/* Alternating the order of items in reverse rows */
.work-item.reverse {
  flex-direction: row-reverse;
}

/* Styling for the title within each work item */
.work-title {
  font-size: 2.5rem;
  flex: 1;
  display: block;
  margin-bottom: 0;
}

/* Ensuring right-aligned text for reversed items */
.work-item.reverse .work-title {
  flex: 1;
}

/* Styling for the video container */
.work-video {
  margin: 0 20px;
  position: relative;
  flex: 1;
}

/* Video styling */
.work-video video {
  width: 550px;
  height: 300px;
  
  transition: transform 0.3s;
  object-fit: cover;
}

/* Hover effect for video */
.work-video video:hover {
  transform: scale(1.1);
}

/* Play button styling */
.play-button {
  width: 100px;
  height: 100px;
  display: none; /* Hide play button by default */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3rem;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
}
.workbutton button{
  background: transparent;
  color: white;
  border: solid 1px;
  border-radius: 10px;
  width: 80px;
  height: 40px;
}
.text-container:hover{
  color: #C82CC8;

}

/* Media queries for responsiveness */
@media (max-width: 1024px) {
  .work-section {
    display: flex;
    flex-direction: column;
  }
  .work-item {
    width: 100%; /* Adjust width for medium screens */
  }
}

@media (max-width: 768px) {
  .work-item {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .work-video {
    order: -1; /* Ensure video always comes first in the column layout */
  }

  .work-title {
    font-size: 2.5rem; /* Adjust font size for smaller screens */
  }

  .work-subtitle {
    font-size: 0.9rem; /* Adjust font size for smaller screens */
  }

  .work-video video {
    width: 100%; 
    max-width: 100%; /* Ensure video stays within container */
    margin-top: 20px; /* Adjust spacing */
  }

  .work-item.reverse {
    display: flex;
    flex-direction: column;
  }

  /* Show play button on mobile screens */
  .play-button {
    display: block;
    
  }
  
}

@media (max-width: 480px) {
  .work-section {
    display: flex;
    flex-direction: column;
  }

  .work-title {
    font-size: 2.5rem; /* Adjust font size for very small screens */
  }

  .work-subtitle {
    font-size: 0.9rem; /* Adjust font size for very small screens */
  }

  .work-video video {
    width: 100%;
    height: auto;
  }

  .work-item.reverse {
    display: flex;
    flex-direction: column;
  }

  /* Show play button on very small screens */
  .play-button {
    display: block;
  }
}
