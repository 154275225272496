.detail-container {
  display: flex;
  color: white;
  padding: 20px;
  justify-content: space-around;
}

.txtimg {
  display: flex;
  
  align-items: center;
  width: 100%;
  flex-wrap: wrap; /* Add this to allow wrapping on smaller screens */
}

.text-content {
  max-width: 80%;
  padding: 20px;
}
.detailvideo video{
  width: 650px;
  height: 400px;
}

.clienttxt {
  width: 280px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.detaildes{
  color: rgb(173, 173, 173);
}
.detailtitle{
  color: #C82CC8;
  font-size: 25px;
  margin-bottom: 0px;
}
.detailsub{
  margin-top: 0px;
}
hr{
  text-align: left;
  margin-left: 0px;
  width: 40%;
}

/* Media query for tablets */
@media (max-width: 768px) {
  .detail-container{
    flex-direction: column;
  }
  .text-content, .detailimg {
    max-width: 100%;
    /* padding: 10px; */
  }
  .detailvideo{margin: auto;
    width: 340px;
    height: 240px;
    text-align: center;
  }
  .detailvideo video{
    width: 340px;
    height: 240px;
  }

  .clienttxt {
    width: 100%;
    justify-content: space-between;
  }
}

/* Media query for mobile devices */
@media (max-width: 480px) {
  .text-content, .detailimg {
    max-width: 100%;
    /* padding: 10px; */
  }

  .clienttxt {
    width: 100%;
    justify-content: space-between;
  }

  .txtimg {
    flex-direction: column;
    align-items: flex-start;
  }
}
