/* Center the section and set the text alignment */
.message-section {
    text-align: center;
    padding: 40px;
    max-width: 600px;
    margin: auto;
    color: white;
  }
  
  .main-heading {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #C82CC8 ;
  }
  
  .subheading {
    font-size: 1.2rem;
    margin-bottom: 40px;
    color: #ffffff;
  }
  
  /* Form styling */
  .message-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .input-row {
    display: flex;
    gap: 20px;
  }
  
  .input-group {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  
  .input-group label {
    font-size: 1rem;
    margin-bottom: 5px;
    color: #ffffff;
    text-align: left;
  }
  
  .input-group input,
  .input-group textarea {
    width: 97%;
    border: none;
    border-bottom: 2px solid #ccc;
    padding: 10px;
    font-size: 1rem;
    outline: none;
    transition: border-color 0.3s;
    background: transparent;
    color: white;
  }
  
  .input-group input:focus,
  .input-group textarea:focus {
    border-bottom: 2px solid #C82CC8;
  }
  
  .input-group textarea {
    resize: none;
    height: 100px;
  }
  
  .messagebtn {
    background: transparent;
    color: white;
    border: 1px solid #ffffff;
    border-radius: 10px;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  button:hover {
    background-color: #C82CC8;
    border: 1px solid #C82CC8;
  }
  